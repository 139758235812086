import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Nav from "./components/nav";
import Lawine from "./pages/lawine";
import Roadblock from "./pages/roadblock";
import Weather from "./pages/weather";
import CancelChain from "./pages/cancelChain";
import CancelLawine from "./pages/cancelLawine";
import Chain from "./pages/chain";
import CancelRoadblock from "./pages/cancelRoadblock";
import Login from "./login/login";

function App() {
  return (
    <Router>
      <div className="flex flex-col gap-10">
        <Nav />
        <main className="p-4 lg:mx-52">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/lawine" element={<Lawine />} />
            <Route path="/cancelLawine" element={<CancelLawine />} />
            <Route path="/roadblock" element={<Roadblock />} />
            <Route path="/cancelRoadblock" element={<CancelRoadblock />} />
            <Route path="/chain" element={<Chain />} />
            <Route path="/cancelChain" element={<CancelChain />} />
            <Route path="/weather" element={<Weather />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
