import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavigationIcon } from "../icons/navigation.svg";

function dashboard() {
  return (
    <div className="flex flex-col gap-10 ">
      <h2>Meldung</h2>
      <div className="flex flex-col gap-4 mb-24">
        <div className="border border-grey50 items-center ">
          <Link
            to="/lawine"
            className="flex flex-row justify-between items-center p-4">
            Künstliche Lawinenauslösung
            <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
        <div className="border border-grey50 items-center ">
          <Link
            to="/cancelLawine"
            className="flex flex-row justify-between p-4 items-center">
            Künstliche Lawinenauslösung Aufhebung
            <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
        <div className="border border-grey50 items-center ">
          <Link
            to="/roadblock"
            className="flex flex-row justify-between p-4 items-center">
            Straßensperre
            <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
        <div className="border border-grey50 items-center ">
          <Link
            to="/cancelRoadblock"
            className="flex flex-row justify-between p-4 items-center">
            Straßensperre Aufhebung
            <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
        <div className="border border-grey50 items-center ">
          <Link
            to="/chain"
            className="flex flex-row justify-between p-4 items-center">
            Kettenpflicht
            <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
        <div className="border border-grey50 items-center ">
          <Link
            to="/cancelChain"
            className="flex flex-row justify-between p-4 items-center">
            Kettenpflicht Aufhebung
            <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
        <div className="border border-grey50 items-center ">
          <Link
            to="/weather"
            className="flex flex-row justify-between p-4 items-center">
            Wetter & Straßensperre
            <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default dashboard;
