import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../icons/arrow_left.svg";

function StepBack({ currentStep, onStepBack }) {
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 1) {
      onStepBack(); // Go to the previous step
    } else {
      navigate(-1); // Navigate to the last page
    }
  };

  return (
    <div className="-mt-5">
      <button onClick={handleBack} className="flex flex-row gap-1 items-center">
        <ArrowLeft /> Zurück
      </button>
    </div>
  );
}

export default StepBack;
