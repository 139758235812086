import React, { useState, useRef, useEffect } from "react";
import Stepper from "../components/stepper";
import { Link } from "react-router-dom";
import Stepback from "../components/stepback";
import FormInput from "../components/formInput";
import MailList from "../components/mailList";
import arrowRight from "../icons/arrow_right.svg";
import TimeDropdown from "../components/timeDropdown";

const Lawine = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const textareaRef = useRef(null);

  const [values, setValues] = useState({
    currentDate: new Date().toISOString().split("T")[0],
    currentTime: "",
    subject: "Kurzzeitige Straßensperre",
    message:
      "Sehr geehrte Damen und Herren! \n \nWir teilen Ihnen mit, dass die P1 Felbertauernstraße von ...",
    date: "",
    duration: "default",
    note: "Bitte nutzen Sie ....",
    emailList: "default",
    startTime: "",
    endTime: "",
  });

  const inputs = [
    {
      id: 1,
      name: "currentDate",
      type: "date",
      label: "Datum der Veröffentlichung",
      required: true,
    },
    {
      id: 2,
      name: "currentTime",
      type: "time",
      label: "Uhrzeit der Veröffentlichung",
      required: true,
    },
    { id: 3, name: "subject", type: "text", label: "Betreff", required: true },
    {
      id: 4,
      name: "message",
      type: "textarea",
      label: "Mitteilung",
      required: true,
      ref: textareaRef,
      rows: 8,
    },
    {
      id: 5,
      name: "date",
      type: "date",
      label: "Datum der Sperre",
      required: true,
    },
    {
      id: 6,
      name: "duration",
      type: "select",
      label: "Dauer",
      placeholder: "Dauer der Sperre",
      required: true,
      options: [
        {
          value: "default",
          label: "Dauer auswählen",
          disabled: true,
        },
        {
          value: "0",
          label: "0 Stunden",
        },
        {
          value: "1",
          label: "1 Stunde",
        },
      ],
    },
    {
      id: 7,
      name: "note",
      type: "textarea",
      label: "Hinweis",
      rows: 3,
      required: true,
    },
  ];

  const handleNextStep = () => {
    const validationErrors = inputs.reduce((errors, input) => {
      if (input.required && !values[input.name]) {
        errors[input.name] = `${input.label} ist erforderlich`;
      }
      return errors;
    }, {});

    if (!values.startTime) {
      validationErrors.startTime = "Bitte ausfüllen";
    }
    if (!values.endTime) {
      validationErrors.endTime = "Bitte ausfüllen";
    }

    if (currentStep === 2 && values.emailList === "default") {
      validationErrors.emailList = "Bitte wählen Sie eine E-Mail aus";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleTimeChange = (name, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    const now = new Date();
    let nextHour = new Date();
    nextHour.setHours(now.getHours() + 1, 0, 0, 0);

    const hours = nextHour.getHours().toString().padStart(2, "0");
    const formattedTime = `${hours}:00`;

    setValues((prevValues) => ({
      ...prevValues,
      currentTime: formattedTime,
    }));
  }, []);

  const handleStepBack = () => setCurrentStep((prevStep) => prevStep - 1);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Final submission logic
  };

  return (
    <div className="pb-10">
      <div className="flex flex-col gap-10">
        <Stepback currentStep={currentStep} onStepBack={handleStepBack} />
        <h3>Künstliche Lawinenauslösung</h3>
      </div>

      <form className="flex flex-col gap-3 mb-3" onSubmit={handleSubmit}>
        {currentStep === 1 && (
          <>
            {inputs.slice(0, 5).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                errorMessage={errors[input.name]}
              />
            ))}
            <div className="flex flex-row w-full items-center gap-2">
              <div className="flex flex-col gap-2 w-full">
                <label className="flex flex-row justify-between">
                  Anfang der Sperre{" "}
                  {errors.startTime && (
                    <span className="text-red text-sm text-right">
                      {errors.startTime}
                    </span>
                  )}
                </label>
                <TimeDropdown
                  name="startTime"
                  value={values.startTime}
                  onChange={(value) => handleTimeChange("startTime", value)}
                  error={errors.startTime}
                />
              </div>

              <div className="flex w-14 pt-8">
                <img src={arrowRight} alt="Arrow Icon" className="w-10 h-10" />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label className="flex flex-row justify-between">
                  Ende der Sperre
                  {errors.endTime && (
                    <span className="text-red text-sm text-right">
                      {errors.endTime}
                    </span>
                  )}
                </label>
                <TimeDropdown
                  name="endTime"
                  value={values.endTime}
                  onChange={(value) => handleTimeChange("endTime", value)}
                  error={errors.endTime}
                />
              </div>
            </div>
            {inputs.slice(5, 7).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                errorMessage={errors[input.name]}
              />
            ))}
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <MailList
              values={values}
              errors={errors}
              onChange={onChange}
              handleNextStep={handleNextStep}
            />
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {currentStep === 3 && (
          <div className="flex flex-col gap-6 -mt-6">
            <p>Das Formular wurde übermittelt.</p>
            <Link className="btn-primary" to="/">
              Zurück zur Startseite
            </Link>
          </div>
        )}
      </form>

      {currentStep <= 2 && (
        <Stepper totalCount={2} activeCount={currentStep - 1} />
      )}
    </div>
  );
};

export default Lawine;
