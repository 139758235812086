import React from "react";
import { Link } from "react-router-dom";

function Login() {
  return (
    <div className="flex flex-col gap-8">
      <h2 className="text-black">Login</h2>
      <form className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <label>
            Benutzername*
            <input
              type="text"
              id="username"
              name="username"
              className="mt-2"
              required
            />
          </label>
          <label>
            Passwort*
            <input type="text" id="pwd" name="pwd" className="mt-2" required />
          </label>
        </div>
        <div className="flex flex-col gap-4">
          <button type="submit" className="btn-primary">
            Einloggen
          </button>
          <Link
            to="/"
            className="uppercase tracking-wide-3px text-xs font-bold border-b-text border-b w-max">
            Passwort vergessen?
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Login;
